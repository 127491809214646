export default [
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'fromDate',
    label: 'field.fromDate',
    rules: 'required',
    type: 'date-picker',
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    rules: 'required',
    type: 'date-picker',
  },
]
